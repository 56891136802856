import clsx from "clsx";
import * as React from "react";

import { Card } from "@repo/ui/Card";
import Link, { LinkColor } from "@repo/ui/Link";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import WidgetTransition from "../components/WidgetTransition";

import Calendar from "./components/Calendar";

import * as s from "./WidgetSchedule.module.scss";

type Props = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const WidgetSchedule: React.FC<Props> = ({ className }) => {
  const { isDesktop } = useWidth();

  const date = React.useMemo(() => new Date(), []);

  const shiftsIds = React.useMemo(
    () =>
      new Set([
        "2024-11-13",
        "2024-11-16",
        "2024-11-18",
        "2024-11-21",
        "2024-11-24",
        "2024-11-28",
        "2024-11-31",
      ]),
    [],
  );

  return (
    <WidgetTransition className={className}>
      <Card className={s["root"]}>
        <div className={s["root__top"]}>
          <Typography
            typographyType={isDesktop ? TypographyType.H3 : TypographyType.H2}
            typographyWeight={TypographyWeight._700}
          >
            График работы
          </Typography>
          <Link
            className={s["root__link"]}
            color={LinkColor.PRIMARY}
            isMobile={!isDesktop}
            text="Посмотреть статистику"
            to="/profile"
          />
        </div>
        <div className={clsx(s["root__card"], s["root__card_primary"])}>
          <div className={s["root__card-group-top-wrapper"]}>
            <div className={s["root__card-group-top-inner"]}>
              <Typography
                className={clsx(
                  s["root__card-title"],
                  s["root__card-title_primary"],
                )}
                typographyType={
                  isDesktop ? TypographyType.CAPS_LG : TypographyType.CAPS_SM
                }
                typographyWeight={TypographyWeight._700}
              >
                Ближайшая смена
              </Typography>
              <div className={s["root__time"]}>
                <div className={s["root__dot"]} />

                <Typography
                  typographyType={
                    isDesktop ? TypographyType.BODY_LG : TypographyType.CAPTION
                  }
                  typographyWeight={
                    isDesktop ? TypographyWeight._700 : TypographyWeight._500
                  }
                >
                  Завтра, 9:00–18:00
                </Typography>
              </div>
            </div>
            <div className={s["root__date"]}>
              <Typography
                typographyType={TypographyType.NUMBERS_MD}
                typographyWeight={TypographyWeight._700}
              >
                18
              </Typography>
              <Typography
                className={s["root__month"]}
                typographyType={
                  isDesktop ? TypographyType.H4 : TypographyType.CAPTION_SM
                }
                typographyWeight={
                  isDesktop ? TypographyWeight._500 : TypographyWeight._400
                }
              >
                янв
              </Typography>
            </div>
          </div>
          <Typography
            typographyType={
              isDesktop ? TypographyType.BODY_SM : TypographyType.CAPTION_SM
            }
            typographyWeight={
              isDesktop ? TypographyWeight._500 : TypographyWeight._400
            }
          >
            Московская область, г. Зеленоград , ул. Новокузнецкая, 13, стр. 3,
            д. 18
          </Typography>
        </div>
        <div className={s["root__group-cards"]}>
          <div
            className={clsx(
              s["root__card"],
              s["root__card_secondary"],
              s["root__card_min-width"],
            )}
          >
            <Typography
              className={s["root__card-title"]}
              typographyType={TypographyType.CAPS_SM}
              typographyWeight={TypographyWeight._700}
            >
              Больничные
            </Typography>
            <Typography
              typographyType={
                isDesktop ? TypographyType.BODY_SM : TypographyType.CAPTION_SM
              }
              typographyWeight={
                isDesktop ? TypographyWeight._500 : TypographyWeight._400
              }
            >
              1 за июнь
            </Typography>
          </div>
          <div className={clsx(s["root__card"], s["root__card_secondary"])}>
            <Typography
              className={s["root__card-title"]}
              typographyType={TypographyType.CAPS_SM}
              typographyWeight={TypographyWeight._700}
            >
              Отпуска (Февраль)
            </Typography>

            <Typography
              typographyType={
                isDesktop ? TypographyType.BODY_SM : TypographyType.CAPTION_SM
              }
              typographyWeight={
                isDesktop ? TypographyWeight._500 : TypographyWeight._400
              }
            >
              12 использовано / 18 запланировано
            </Typography>
          </div>
        </div>
        <Calendar date={date} shifts={shiftsIds} />
      </Card>
    </WidgetTransition>
  );
};

export default WidgetSchedule;
