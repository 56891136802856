import { forwardRef, memo } from "react";

import Input, { type InputCommonProps } from "../Input";

export type InputBaseProps = InputCommonProps &
  Omit<React.InputHTMLAttributes<HTMLInputElement>, keyof InputCommonProps>;

const InputBase = forwardRef<HTMLInputElement, InputBaseProps>(
  (props: InputBaseProps, refForward) => {
    return (
      <Input ref={refForward} {...props}>
        {({ ref, handleChange, ...props }) => (
          <input
            ref={ref as React.RefObject<HTMLInputElement>}
            onChange={handleChange}
            {...props}
          />
        )}
      </Input>
    );
  },
);

InputBase.displayName = "InputBase";

export default memo(InputBase);
