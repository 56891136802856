// extracted by mini-css-extract-plugin
var _1 = "root___M4kf";
var _2 = "root__navigation__e_yYI";
var _3 = "root__navigation_disabled__AR5CS";
var _4 = "root__navigation_next__qsj2n";
var _5 = "root__navigation_prev__C15bK";
var _6 = "root__pagination-placeholder__IGkuN";
var _7 = "root__slide__gaNVM";
var _8 = "root__swiper__eV2nu";
export { _1 as "root", _2 as "root__navigation", _3 as "root__navigation_disabled", _4 as "root__navigation_next", _5 as "root__navigation_prev", _6 as "root__pagination-placeholder", _7 as "root__slide", _8 as "root__swiper" }
