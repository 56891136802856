import type { story } from "@repo/api/types";
import type { CollectionType } from "@repo/types/collection";

import StoryModel, { type IStoryModel } from "../../models/StoryModel";

export const normalizeStories = (
  rawData: story.StoryApi[],
): CollectionType<number, IStoryModel> =>
  rawData.reduce<CollectionType<number, IStoryModel>>(
    (acc, item) => {
      acc.order.push(item.id);
      acc.entities[item.id] = new StoryModel(item);

      return acc;
    },
    { order: [], entities: {} },
  );
