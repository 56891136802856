import clsx from "clsx";
import { memo, useMemo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { MoneyValueSize } from "./config";

import * as s from "./MoneyValue.module.scss";

export type MoneyValueProps = {
  /** Значение денег. */
  value: number;
  /** Размер текста. */
  size: MoneyValueSize;
  /** Если true, значение будет размыто. */
  isVisible: boolean;
};

const MoneyValue: React.FC<MoneyValueProps> = ({
  value,
  size,
  isVisible,
}: MoneyValueProps) => {
  const { sign, integerPart, fractionalPart } = useMemo(() => {
    const sign = value > 0 ? "+" : "-";

    const absoluteValue = Math.abs(value);
    const integerPart = Math.floor(absoluteValue);
    const fractionalPart = (absoluteValue - integerPart).toFixed(2).slice(2);

    return {
      sign: value === 0 ? "" : sign,
      integerPart: integerPart.toLocaleString(),
      fractionalPart,
    };
  }, [value]);

  return (
    <Typography
      className={clsx(
        s["root"],
        size && s[`root_size-${size}`],
        !isVisible && s["root_blur"],
      )}
      typographyType={TypographyType.BODY_MD}
      typographyWeight={TypographyWeight._500}
    >
      {sign.length > 0 && <span>{sign}&nbsp;</span>}
      <span>{integerPart}</span>
      <span className={s["root__fractional"]}>.{fractionalPart}&nbsp;руб.</span>
    </Typography>
  );
};

export default memo(MoneyValue);
