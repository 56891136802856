/**
 * Возвращает указанное значение, а также значения до и после него.
 *
 * @param item Искомое значение.
 * @param list Массив значений.
 *
 * @returns Новый массив с текущим значением, значением до и после него, либо пустой массив.
 */
export function getAdjacentValues<T>(item: T, list: T[]): T[] {
  const itemIndex = list.indexOf(item);

  if (itemIndex === -1) {
    return [];
  }

  const start = Math.max(itemIndex - 1, 0);
  const end = Math.min(itemIndex + 2, list.length);

  return list.slice(start, end);
}
