import { action, makeObservable, observable, runInAction } from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import type { story } from "@repo/api/types";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import type { SlideType } from "@repo/types/story";

import { normalizeSlides, normalizeStory } from "./normalize";
import type { IStoryModel } from "./types";

class StoryModel implements IStoryModel {
  private readonly _meta = new MetaStore();

  id: number;
  title: string;
  isPinned: boolean;
  isViewed: boolean;
  createdAt: string;
  attachment: string;
  slides: SlideType[] | null = null;

  constructor(rawStory: story.StoryApi) {
    const normalizedStory = normalizeStory(rawStory);

    this.id = normalizedStory.id;
    this.title = normalizedStory.title;
    this.isPinned = normalizedStory.isPinned;
    this.isViewed = normalizedStory.isViewed;
    this.createdAt = normalizedStory.createdAt;
    this.attachment = normalizedStory.attachment;

    makeObservable(this, {
      isViewed: observable,
      slides: observable.ref,

      setViewed: action,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  async setViewed(): Promise<void> {
    if (this.isViewed) {
      return;
    }

    const { data, isError } = await apiWithChecks(
      ApiPath.STORY_SET_VIEWED,
      "POST",
      {
        data: { ids: [this.id] },
      },
    );

    if (!data || isError) {
      return;
    }

    this.isViewed = data.ids.includes(this.id);
  }

  async fetchSlides(): Promise<void> {
    if (this.meta.isLoading || this.slides) {
      return;
    }

    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(ApiPath.STORY_ITEM, "GET", {
      data: { id: this.id },
    });

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      this.slides = normalizeSlides(data.slides);
      this._meta.setLoadedSuccessMeta();
    });
  }
}

export default StoryModel;
