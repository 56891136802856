import { memo } from "react";

import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import MoneyValue, { MoneyValueSize } from "../../MoneyValue";

import { CHART_LEGEND_MAP, ChartLegendItemEnum } from "./config";

import * as s from "./ChartLegendItem.module.scss";

export type ChartLegendItemProps = {
  /** ID легенды графика. */
  id: ChartLegendItemEnum;
  /** Значение денег. */
  value: number;
  /** Если true, значение будет размыто. */
  isVisible: boolean;
};

const ChartLegendItem: React.FC<ChartLegendItemProps> = ({
  id,
  value,
  isVisible,
}: ChartLegendItemProps) => {
  const { label, color } = CHART_LEGEND_MAP[id];

  return (
    <div className={s["root"]}>
      <div
        className={s["root__dot"]}
        style={{
          backgroundColor: color,
        }}
      />

      <Typography
        className={s["root__label"]}
        typographyType={TypographyType.BODY_SM}
        typographyWeight={TypographyWeight._500}
      >
        {label}
      </Typography>

      <MoneyValue
        isVisible={isVisible}
        size={MoneyValueSize.SMALL}
        value={value}
      />
    </div>
  );
};

export default memo(ChartLegendItem);
