import clsx from "clsx";
import { observer } from "mobx-react-lite";

import { useLocalStore } from "@repo/data/hooks";
import { IconType } from "@repo/types/icon";
import { CardEmployee, CardTab } from "@repo/ui/Card";

import useWidth from "@app/hooks/useWidth";
import { CollapseStore } from "@app/store/local/CollapseStore";

import { EMPLOYEE_DATA, ORGANIZATION_DATA } from "./mocks/__mocks__";

import * as s from "./CollapseTabs.module.scss";

export type CollapseTabsProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const CollapseTabs: React.FC<CollapseTabsProps> = ({
  className,
}: CollapseTabsProps) => {
  const { isPhone } = useWidth();

  const collapseStore = useLocalStore(() => new CollapseStore());

  return (
    <div className={clsx(s["root"], className)}>
      <CardTab
        card={{
          id: "Руководитель",
          title: "Руководитель",
          iconType: IconType.PEOPLE_3D,
        }}
        defaultOpened={
          isPhone ? collapseStore.isTabOpened("Руководитель") : true
        }
        isCollapsable={isPhone}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        {({ isOpened, isCollapsable }) => (
          <CardTab.Content isCollapsable={isCollapsable} isOpened={isOpened}>
            <CardEmployee
              key={EMPLOYEE_DATA.id}
              employee={EMPLOYEE_DATA}
              isMobile={isPhone}
              withBorder={false}
            />
          </CardTab.Content>
        )}
      </CardTab>

      <CardTab
        isCollapsable
        card={{
          id: "Организационные данные",
          title: "Организационные данные",
          iconType: IconType.INFO_3D,
        }}
        defaultOpened={collapseStore.isTabOpened("Организационные данные")}
        isMobile={isPhone}
        onToggleClick={collapseStore.toggleTab}
      >
        {({ isOpened, isCollapsable }) => {
          return (
            <>
              <CardTab.Content>
                {ORGANIZATION_DATA.slice(0, 2).map((item) => (
                  <CardTab.Detail
                    key={item.id}
                    text={item.text}
                    title={item.title}
                  />
                ))}
              </CardTab.Content>

              <CardTab.Content
                isCollapsable={isCollapsable}
                isOpened={isOpened}
              >
                {ORGANIZATION_DATA.slice(2).map((item) => (
                  <CardTab.Detail
                    key={item.id}
                    text={item.text}
                    title={item.title}
                  />
                ))}
              </CardTab.Content>
            </>
          );
        }}
      </CardTab>
    </div>
  );
};

export default observer(CollapseTabs);
