export enum EmployeeStatus {
  /** Удаленная работа. */
  REMOTE = "удаленная работа",
  /** В отпуске. */
  VACATION = "в отпуске",
  /** Дэй-офф. */
  DAY_OFF = "дэй-офф",
  /** Не работает в компании. */
  FIRED = "не работает в компании",
}
