import { Link } from "@tanstack/react-router";
import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import { AvatarImage, AvatarSize } from "@repo/ui/Avatar";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import type { EmployeeInfo } from "./types";

import * as s from "./CardEmployee.module.scss";

export type CardEmployeeProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Информация о сотруднике. */
  employee: EmployeeInfo;
  /* Статусы сотрудника. */
  tags?: React.ReactNode;
  /** Иконка кнопки открытия. */
  iconType?: IconType;
  /** Показывать ли границу у карточки. */
  withBorder?: boolean;
  /** Мобильная версия. */
  isMobile: boolean;
};

const CardEmployee: React.FC<CardEmployeeProps> = ({
  className,
  employee,
  tags,
  iconType = IconType.EXTEND,
  withBorder = true,
  isMobile,
}: CardEmployeeProps) => {
  return (
    <div
      className={clsx(
        s["root"],
        withBorder && s["root_with-border"],
        className,
      )}
    >
      <AvatarImage
        round
        alt={employee.name}
        size={AvatarSize.S}
        src={employee.avatar}
      />

      <div className={s["root__content"]}>
        <div className={s["root__header"]}>
          <div className={s["root__title"]}>
            <Typography
              Component="p"
              className={s["root__name"]}
              typographyType={
                isMobile ? TypographyType.BODY_SM : TypographyType.BODY_LG
              }
              typographyWeight={TypographyWeight._700}
            >
              {employee.name}
            </Typography>

            <Typography
              Component="p"
              className={s["root__position"]}
              typographyType={
                isMobile ? TypographyType.CAPTION : TypographyType.BODY_SM
              }
              typographyWeight={TypographyWeight._500}
            >
              {employee.position}
            </Typography>
          </div>

          {tags && <div className={s["root__tags"]}>{tags}</div>}
        </div>

        <div className={s["root__details"]}>
          {employee.details.map((detail) => (
            <div key={detail.id} className={s["root__detail"]}>
              <Typography
                Component="p"
                className={s["root__detail-title"]}
                typographyType={
                  isMobile ? TypographyType.CAPTION : TypographyType.BODY_SM
                }
                typographyWeight={
                  isMobile ? TypographyWeight._500 : TypographyWeight._400
                }
              >
                {detail.title}
              </Typography>

              <Typography
                Component="p"
                className={s["root__detail-text"]}
                typographyType={
                  isMobile ? TypographyType.CAPTION : TypographyType.BODY_MD
                }
                typographyWeight={TypographyWeight._500}
              >
                {detail.text}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <Link
        className={s["root__button"]}
        params={{ employeeId: employee.id }}
        to="/employee/$employeeId"
      >
        <Icon size={IconSize.M} type={iconType} />
      </Link>
    </div>
  );
};

export default memo(CardEmployee);
