import clsx from "clsx";
import { memo, type PropsWithChildren } from "react";

import * as s from "./Card.module.scss";

export type CardProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const Card: React.FC<PropsWithChildren<CardProps>> = ({
  className,
  children,
}: PropsWithChildren<CardProps>) => {
  return <div className={clsx(s["card"], className)}>{children}</div>;
};

export default memo(Card);
