import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useRef, useState } from "react";
import { EffectFade, Pagination, Navigation } from "swiper/modules";
import {
  Swiper,
  type SwiperClass,
  type SwiperRef,
  SwiperSlide,
} from "swiper/react";

import GlobalStoriesStore from "@repo/data/store/single/GlobalStoriesStore";
import Modal from "@repo/ui/Modal";

import useModalStories from "@app/hooks/useModalStories";
import useWidth from "@app/hooks/useWidth";

import ModalNavigation from "./components/ModalNavigation";
import SlideItem from "./components/SlideItem";

import * as s from "./ModalStories.module.scss";

const ModalStories: React.FC = () => {
  const { isPhone } = useWidth();

  const swiperParentRef = useRef<SwiperRef | null>(null);
  const swiperChildrenRef = useRef<Map<number, SwiperClass>>(new Map());

  const modalStories = useModalStories();
  const storiesStore = GlobalStoriesStore.getInstance();

  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const initialStoryIndex = useMemo(() => {
    return storiesStore.order.findIndex(
      (id) => id === storiesStore.activeStory?.id,
    );
  }, [storiesStore.order, storiesStore.activeStory]);

  const handleBeforeInit = useCallback(async () => {
    await storiesStore.fetchSiblingSlides();
  }, [storiesStore]);

  const handleSlideChange = useCallback(
    async (swiper: SwiperClass) => {
      const storyId = storiesStore.order[swiper.activeIndex];

      const activeStory = storiesStore.getStoryById(storyId!);
      storiesStore.setActiveStory(activeStory);

      // Запрашиваем слайды для соседних истории.
      await storiesStore.fetchSiblingSlides();

      // Подгружаем новые истории, если прогресс прокрутки больше 70%.
      // Не используется событие `reachEnd`, чтобы сгладить переход.
      if (swiper.progress >= 0.7) {
        await storiesStore.fetchNextStories();

        swiper.update();
      }
    },
    [storiesStore],
  );

  const handleSlideChangeTransitionEnd = useCallback(
    async (swiper: SwiperClass) => {
      const storyId = storiesStore.order[swiper.activeIndex];
      const activeChildSwiper = swiperChildrenRef.current.get(storyId!);

      if (!activeChildSwiper) {
        return;
      }

      // Если у истории один слайд, то считаем ее просмотренной.
      // Обязательно вызывать в событии `onSlideChangeTransitionEnd`
      if (activeChildSwiper.isBeginning && activeChildSwiper.isEnd) {
        await storiesStore.activeStory?.setViewed();
      }
    },
    [storiesStore],
  );

  const handleSnapIndexChange = useCallback(
    (swiper: SwiperClass) => {
      const storyId = storiesStore.order[swiper.activeIndex];
      const activeChildSwiper = swiperChildrenRef.current.get(storyId!);

      if (!activeChildSwiper) {
        return;
      }

      // Скрываем навигацию, если достигнут конец родительского и дочернего swiper.
      setIsBeginning(swiper.isBeginning && activeChildSwiper.isBeginning);
      setIsEnd(swiper.isEnd && activeChildSwiper.isEnd);
    },
    [storiesStore],
  );

  const handleChildSlideChange = useCallback(
    async (swiper: SwiperClass) => {
      const parentSwiper = swiperParentRef.current?.swiper;

      if (!parentSwiper) {
        return;
      }

      // Скрываем навигацию, если достигнут конец родительского и дочернего swiper.
      // Актуально для историй с несколькими слайдами.
      setIsBeginning(parentSwiper.isBeginning && swiper.isBeginning);
      setIsEnd(parentSwiper.isEnd && swiper.isEnd);

      // Если дочерний swiper достиг конца, считаем историю просмотренной.
      // Актуально для историй с несколькими слайдами.
      if (swiper.isEnd) {
        await storiesStore.activeStory?.setViewed();
      }
    },
    [storiesStore],
  );

  const handlePrev = useCallback(() => {
    const parentSwiper = swiperParentRef.current?.swiper;
    const activeChildSwiper = swiperChildrenRef.current.get(
      storiesStore.activeStory!.id,
    );

    if (!parentSwiper || !activeChildSwiper) {
      return;
    }

    if (!activeChildSwiper.isBeginning) {
      activeChildSwiper.slidePrev();
      return;
    }

    if (!parentSwiper.isBeginning && activeChildSwiper.isBeginning) {
      parentSwiper.slidePrev();
      return;
    }
  }, [storiesStore]);

  const handleNext = useCallback(() => {
    const parentSwiper = swiperParentRef.current?.swiper;
    const activeChildSwiper = swiperChildrenRef.current.get(
      storiesStore.activeStory!.id,
    );

    if (!parentSwiper || !activeChildSwiper) {
      return;
    }

    if (!activeChildSwiper.isEnd) {
      activeChildSwiper.slideNext();
      return;
    }

    if (!parentSwiper.isEnd && activeChildSwiper.isEnd) {
      parentSwiper.slideNext();
      return;
    }
  }, [storiesStore]);

  return (
    <Modal
      className={s["root"]}
      isMobile={isPhone}
      isOpened={modalStories.isOpened}
      onClose={modalStories.handleClose}
    >
      <Modal.Content
        isMobile
        className={s["root__content"]}
        onClose={modalStories.handleClose}
      >
        <Swiper
          ref={swiperParentRef}
          observeSlideChildren
          observer
          className={s["root__swiper"]}
          effect="fade"
          initialSlide={initialStoryIndex}
          loop={false}
          modules={[EffectFade, Navigation]}
          navigation={{
            enabled: true,
            prevEl: null,
            nextEl: null,
          }}
          slidesPerView={1}
          onBeforeInit={handleBeforeInit}
          onSlideChange={handleSlideChange}
          onSlideChangeTransitionEnd={handleSlideChangeTransitionEnd}
          onSnapIndexChange={handleSnapIndexChange}
        >
          {storiesStore.stories.map((story) => (
            <SwiperSlide key={story.id} className={s["root__slide"]}>
              <Swiper
                nested
                className={s["root__swiper"]}
                effect="fade"
                loop={false}
                modules={[EffectFade, Pagination, Navigation]}
                navigation={{
                  enabled: true,
                  prevEl: null,
                  nextEl: null,
                }}
                pagination={{
                  type: "progressbar",
                }}
                slidesPerView={1}
                onSlideChange={handleChildSlideChange}
                onSwiper={(swiper: SwiperClass) => {
                  swiperChildrenRef.current.set(story.id, swiper);
                }}
              >
                {story.slides?.map((slide) => (
                  <SwiperSlide key={slide.id} className={s["root__slide"]}>
                    <SlideItem item={slide} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </SwiperSlide>
          ))}
        </Swiper>

        <ModalNavigation
          isBeginning={isBeginning}
          isEnd={isEnd}
          onNext={handleNext}
          onPrev={handlePrev}
        />

        {!isPhone && (
          <Modal.Close
            className={s["root__close"]}
            onClick={modalStories.handleClose}
          />
        )}
      </Modal.Content>
    </Modal>
  );
};

export default observer(ModalStories);
