import { format } from "date-fns";

/**
 * Форматирование даты в формате dd.MM.yyyy
 *
 * @param {Date | null} date - дата
 * @returns {string}
 */
export const formatDateDDMMYYYY = (date: Date | null): string =>
  format(date || new Date(), "dd.MM.yyyy");
