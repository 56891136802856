import clsx from "clsx";
import { motion } from "framer-motion";
import { memo, useId } from "react";

import { SwitchColor, SwitchSize } from "./config";

import * as s from "./Switch.module.scss";

export type SwitchProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Размер переключателя. */
  size?: SwitchSize;
  /** Цвет переключателя. */
  color?: SwitchColor;
  /** Текст лейбла переключателя. */
  label?: string;
  /** Включен ли переключатель. */
  checked?: boolean;
  /** Обработчик изменения значения переключателя. */
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  /** Компонент, который можно разместить внутри пина. */
  children?: (props: { checked?: boolean }) => React.ReactNode;
};

const Switch: React.FC<SwitchProps> = ({
  className,
  size = SwitchSize.M,
  color = SwitchColor.PRIMARY,
  label,
  checked,
  onChange,
  children,
  ...props
}: SwitchProps) => {
  const id = useId();

  return (
    <div className={clsx(s["switch"], className)}>
      <input
        checked={checked}
        className={s["switch__input"]}
        id={id}
        type="checkbox"
        onChange={onChange}
        {...props}
      />

      <label
        className={clsx(
          s["switch__label"],
          s[`switch__label_size-${size}`],
          s[`switch__label_color-${color}`],
        )}
        data-is-checked={checked}
        htmlFor={id}
      >
        {label && <span className={s["switch__text"]}>{label}</span>}

        <motion.span
          layout
          className={clsx(
            s["switch__pin"],
            s[`switch__pin_size-${size}`],
            s[`switch__pin_color-${color}`],
          )}
          transition={{ duration: 0.5 }}
        >
          {children && children({ checked })}
        </motion.span>
      </label>
    </div>
  );
};

export default memo(Switch);
