import { ChartLegendItemType } from "./types";

export enum ChartLegendItemEnum {
  /** Выплаты */
  INCOME = "income",
  /** Удержания */
  EXPENSE = "expense",
}

export const CHART_LEGEND_MAP: Record<
  ChartLegendItemEnum,
  ChartLegendItemType
> = {
  [ChartLegendItemEnum.INCOME]: {
    label: "Выплаты",
    color: "#8a60ff",
  },
  [ChartLegendItemEnum.EXPENSE]: {
    label: "Удержания",
    color: "#f72874",
  },
};

// TODO как станет понятно, что это за значения, нужно будет переименовать
export enum ChartLegendExtraItemEnum {
  EXTRA_ONE = "extra-one",
  EXTRA_TWO = "extra-two",
  EXTRA_THREE = "extra-three",
  EXTRA_FOUR = "extra-four",
  EXTRA_FIVE = "extra-five",
  EXTRA_SIX = "extra-six",
}

export const CHART_LEGEND_EXTRA_MAP: Record<
  ChartLegendExtraItemEnum,
  ChartLegendItemType
> = {
  [ChartLegendExtraItemEnum.EXTRA_ONE]: {
    label: "Дополнительный 1",
    color: "#84d1ef",
  },
  [ChartLegendExtraItemEnum.EXTRA_TWO]: {
    label: "Дополнительный 2",
    color: "#a5c625",
  },
  [ChartLegendExtraItemEnum.EXTRA_THREE]: {
    label: "Дополнительный 3",
    color: "#7b69f2",
  },
  [ChartLegendExtraItemEnum.EXTRA_FOUR]: {
    label: "Дополнительный 4",
    color: "#20448c",
  },
  [ChartLegendExtraItemEnum.EXTRA_FIVE]: {
    label: "Дополнительный 5",
    color: "#009aaf",
  },
  [ChartLegendExtraItemEnum.EXTRA_SIX]: {
    label: "Дополнительный 6",
    color: "#f72874",
  },
};
