import { action, makeObservable, observable } from "mobx";

import type { ILocalStore } from "../../interfaces/ILocalStore";

import type { PageInfoType } from "./types";

class PaginationStore implements ILocalStore {
  private _currentPage: number = 1;
  private _count: number;

  constructor(count: number) {
    this._count = count;

    makeObservable<this, "_currentPage">(this, {
      _currentPage: observable,
      setPage: action,
    });
  }

  get pageInfo(): PageInfoType {
    return {
      currentPage: this._currentPage,
      count: this._count,
    };
  }

  setPage(page: number): void {
    this._currentPage = page;
  }

  nextPage(): void {
    this.setPage(this._currentPage++);
  }

  prevPage(): void {
    this.setPage(this._currentPage--);
  }

  destroy(): void {
    // do nothing
  }
}

export default PaginationStore;
