import { createFileRoute } from "@tanstack/react-router";
import { useState } from "react";

import { InputSearch } from "@repo/ui/Input";
import Loader, { LoaderSize } from "@repo/ui/Loader";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import LayoutPage from "@app/pages/auth/LayoutPage";
import EmployeeList from "@app/pages/employees/EmployeeList";

import * as s from "./employees.module.scss";

const AuthEmployeesPage: React.FC = () => {
  const [searchValue, setSearchValue] = useState<string>("");
  // eslint-disable-next-line no-console
  const onSearch = () => console.log("onSearch", searchValue);

  return (
    <LayoutPage className={s["employees"]}>
      <InputSearch
        value={searchValue}
        onChange={setSearchValue}
        onSearch={onSearch}
      />

      <Typography
        className={s["employees__title"]}
        typographyType={TypographyType.H1}
        typographyWeight={TypographyWeight._700}
      >
        Сотрудники
      </Typography>

      <EmployeeList className={s["employees__list"]} />
    </LayoutPage>
  );
};

export const Route = createFileRoute("/_auth/employees")({
  component: AuthEmployeesPage,
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});
