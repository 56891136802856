import type { CollectionType } from "@repo/types/collection";

/**
 * Преобразует коллекцию в массив.
 *
 * @param collection - Коллекция для преобразования.
 * @returns Коллекция преобразованная в массив.
 */
export function linearizeCollection(
  collection: CollectionType<string | number, any>,
): Array<any> {
  return collection.order.map((item) => collection.entities[item]);
}

/**
 * Преобразует массив в коллекцию.
 *
 * @param list - Массив для преобразования.
 * @returns Преобразованная коллекция.
 */
export function listToCollection<
  KeyType extends string | number,
  Entity extends { key: KeyType },
>(list: Entity[]): CollectionType<KeyType, Entity> {
  try {
    return list.reduce<CollectionType<KeyType, Entity>>(
      (acc, item) => {
        acc.order.push(item.key);
        acc.entities[item.key] = item;

        return acc;
      },
      { order: [], entities: {} },
    );
  } catch (e) {
    return {
      order: [],
      entities: {},
    };
  }
}
