import clsx from "clsx";
import { useRef, type PropsWithChildren } from "react";

import useWidth from "@app/hooks/useWidth";

import ModalStories from "../ModalStories";

import { ContentContext } from "./context";

import * as s from "./Layout.module.scss";

export type LayoutProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Содержимое header страницы. */
  headerContent: React.ReactNode;
  /** Содержимое для боковой панели. */
  sidebarContent: React.ReactNode;
  /** Содержимое для мобильного меню. */
  menuMobile: React.ReactNode;
};

const Layout: React.FC<PropsWithChildren<LayoutProps>> = ({
  className,
  headerContent,
  sidebarContent,
  menuMobile,
  children,
}: PropsWithChildren<LayoutProps>) => {
  const contentRef = useRef<HTMLDivElement | null>(null);

  const { isPhone } = useWidth();

  return (
    <div className={clsx(s["layout"], className)}>
      <div className={s["layout__container"]}>
        <aside className={s["layout__panel"]}>
          <div className={s["layout__sidebar"]}>{sidebarContent}</div>
        </aside>

        <section ref={contentRef} className={s["layout__content"]}>
          <ContentContext.Provider value={contentRef}>
            <div className={s["layout__header"]}>{headerContent}</div>
            <div className={s["layout__children"]}>{children}</div>
          </ContentContext.Provider>
        </section>

        {isPhone && (
          <section className={s["layout__menu-mobile"]}>{menuMobile}</section>
        )}
      </div>

      <ModalStories />
    </div>
  );
};

export default Layout;
