import clsx from "clsx";
import { type PropsWithChildren } from "react";

import * as s from "./LayoutPage.module.scss";

export type LayoutPageProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const LayoutPage: React.FC<PropsWithChildren<LayoutPageProps>> = ({
  className,
  children,
}: PropsWithChildren<LayoutPageProps>) => {
  return <div className={clsx(s["layout-page"], className)}>{children}</div>;
};

export default LayoutPage;
