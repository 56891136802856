import type { user } from "@repo/api/types";

import S3ResourceModel from "../../models/S3ResourceModel";

import type { UserType } from "./types";

export const normalizeUser = (data: user.UserCurrentApi): UserType => ({
  userId: data.user_id,
  username: data.username,
  fullName: data.full_name,
  employeeNumber: data.employee_number,
  phone: data.phone,
  email: data.email,
  avatar: data.avatar ? new S3ResourceModel(data.avatar).url : null,
});
