import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Button from "@repo/ui/Button";
import Icon, { IconDirection, IconSize } from "@repo/ui/Icon";

import * as s from "./ModalNavigation.module.scss";

export type ModalNavigationProps = {
  /** Флаг, отключающий кнопку назад. */
  isEnd: boolean;
  /** Флаг, отключающий кнопку вперед. */
  isBeginning: boolean;
  /** Callback на нажатие кнопки назад. */
  onPrev: () => void;
  /** Callback на нажатие кнопки вперед. */
  onNext: () => void;
};

const ModalNavigation: React.FC<ModalNavigationProps> = ({
  isEnd,
  isBeginning,
  onPrev,
  onNext,
}: ModalNavigationProps) => {
  return (
    <div className={s["root"]}>
      <Button.Default
        className={clsx(
          s["root__button"],
          isBeginning && s["root__button_disabled"],
        )}
        onClick={onPrev}
      >
        <Icon
          direction={IconDirection.DOWN}
          size={IconSize.L}
          type={IconType.ARROW_RIGHT}
        />
      </Button.Default>

      <Button.Default
        className={clsx(s["root__button"], isEnd && s["root__button_disabled"])}
        onClick={onNext}
      >
        <Icon
          direction={IconDirection.UP}
          size={IconSize.L}
          type={IconType.ARROW_RIGHT}
        />
      </Button.Default>
    </div>
  );
};

export default memo(ModalNavigation);
