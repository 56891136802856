import avatarOne from "./employee-1.png";
import avatarTwo from "./employee-2.png";
import avatarThree from "./employee-3.png";

type EmployeeDetail = {
  id: string;
  title: string;
  text: string;
};

type EmployeeInfo = {
  id: string;
  avatar: string;
  name: string;
  position: string;
  details: EmployeeDetail[];
};

export const EMPLOYEE_DATA: EmployeeInfo[] = [
  {
    id: "1",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "2",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "3",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "4",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "5",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "6",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "7",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "8",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "9",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "10",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "11",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "12",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "13",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "14",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "15",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "16",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "17",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "18",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "19",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "20",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "21",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "22",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "23",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "24",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "25",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "26",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "27",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
  {
    id: "28",
    name: "Андреев Сергей Геннадиевич",
    position: "Руководитель отдела закупок",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarOne,
  },
  {
    id: "29",
    name: "Митрофанова Ольга Павловна",
    position: "Менеджер по продажам",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarTwo,
  },
  {
    id: "30",
    name: "Отпетова Надежда Константиновна",
    position: "Менеджер отдела кадров",
    details: [
      { id: "1", title: "Табельный номер", text: "0422" },
      { id: "2", title: "Телефон", text: "+7 964 999-99-98" },
      { id: "3", title: "Электронная почта", text: "andreev@hello-hr.ru" },
      {
        id: "4",
        title: "Электронная почта",
        text: "Любимая компания / филиал Волга / Отдел закупок",
      },
    ],
    avatar: avatarThree,
  },
];
