import { useCallback } from "react";

import type { IStoryModel } from "@repo/data/store/models/StoryModel";
import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import GlobalStoriesStore from "@repo/data/store/single/GlobalStoriesStore";

import { useModalsStoreOpenState } from "./useModalsStoreOpenState";

function useModalStories() {
  const globalStoriesStore = GlobalStoriesStore.getInstance();
  const modalStore = useModalsStoreOpenState(ModalsBaseKeys.STORY);

  const handleOpen = useCallback(
    async (story: IStoryModel) => {
      globalStoriesStore.setActiveStory(story);

      await story.fetchSlides();

      modalStore.open();
    },
    [globalStoriesStore, modalStore],
  );

  const handleClose = useCallback(() => {
    globalStoriesStore.setActiveStory(null);

    modalStore.close();
  }, [globalStoriesStore, modalStore]);

  return {
    isOpened: modalStore.opened,
    handleOpen,
    handleClose,
  };
}

export default useModalStories;
