import {
  type LinkProps as RouterLinkProps,
  Link as RouterLink,
} from "@tanstack/react-router";
import clsx from "clsx";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Icon from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { LinkColor } from "./config";

import * as s from "./Link.module.scss";

export type LinkProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Цвет ссылки. */
  color: LinkColor;
  /** Текст ссылки. */
  text: string;
  /** Мобильная версия. */
  isMobile: boolean;
} & RouterLinkProps;

const Link: React.FC<LinkProps> = ({
  className,
  color,
  text,
  isMobile,
  ...props
}: LinkProps) => {
  return (
    <RouterLink
      className={clsx(s["root"], s[`root_color-${color}`], className)}
      {...props}
    >
      <Typography
        className={s["root__text"]}
        typographyType={
          isMobile ? TypographyType.CAPTION_SM : TypographyType.BODY_SM
        }
        typographyWeight={TypographyWeight._500}
      >
        {text} <Icon className={s["root__icon"]} type={IconType.ARROW_RIGHT} />
      </Typography>
    </RouterLink>
  );
};

export default memo(Link);
