import clsx from "clsx";
import { memo } from "react";

import { EmployeeStatus as Status } from "@repo/types/employee";
import Tag, { TagAngle, TagColor, TagSize } from "@repo/ui/Tag";

import * as s from "./EmployeeStatus.module.scss";

export type EmployeeStatusProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const EmployeeStatus: React.FC<EmployeeStatusProps> = ({
  className,
}: EmployeeStatusProps) => {
  return (
    <div className={clsx(s["status"], className)}>
      <Tag
        angle={TagAngle.BOTTOM_LEFT}
        color={TagColor.TERTIARY}
        label={Status.VACATION}
        size={TagSize.S}
      />

      <Tag
        angle={TagAngle.BOTTOM_LEFT}
        color={TagColor.SECONDARY}
        label={Status.REMOTE}
        size={TagSize.S}
      />
    </div>
  );
};

export default memo(EmployeeStatus);
