import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback, useRef, useState } from "react";
import { Pagination, Navigation } from "swiper/modules";
import {
  Swiper,
  type SwiperClass,
  type SwiperRef,
  SwiperSlide,
} from "swiper/react";

import GlobalStoriesStore from "@repo/data/store/single/GlobalStoriesStore";
import { IconType } from "@repo/types/icon";
import Button from "@repo/ui/Button";
import Icon, { IconDirection, IconSize } from "@repo/ui/Icon";

import useWidth from "@app/hooks/useWidth";

import StoryItem from "./StoryItem";

import * as s from "./WidgetStories.module.scss";

const WidgetStories: React.FC = () => {
  const { isPhone } = useWidth();
  const swiperRef = useRef<SwiperRef | null>(null);

  const storiesStore = GlobalStoriesStore.getInstance();

  const [isBeginning, setIsBeginning] = useState<boolean>(false);
  const [isEnd, setIsEnd] = useState<boolean>(false);

  const handleAfterInit = useCallback((swiper: SwiperClass) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  }, []);

  const handleSlideChange = useCallback(
    async (swiper: SwiperClass) => {
      // Подгружаем новые истории, если прогресс прокрутки больше 70%.
      // Не используется событие `reachEnd`, чтобы сгладить переход.
      if (swiper.progress >= 0.7) {
        await storiesStore.fetchNextStories();

        swiper.update();
      }

      setIsBeginning(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
    },
    [storiesStore],
  );

  const handlePrev = useCallback(() => {
    swiperRef.current?.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    swiperRef.current?.swiper.slideNext();
  }, []);

  return (
    <div className={s["root"]}>
      <Swiper
        ref={swiperRef}
        className={s["root__swiper"]}
        modules={[Navigation, Pagination]}
        navigation={{
          enabled: true,
          prevEl: null,
          nextEl: null,
        }}
        pagination={{
          enabled: true,
          modifierClass: "widget-swiper-pagination-",
          formatFractionTotal: () => storiesStore.stories.length,
        }}
        slidesPerView="auto"
        spaceBetween={isPhone ? 15 : 28}
        onAfterInit={handleAfterInit}
        onSlideChange={handleSlideChange}
      >
        {storiesStore.stories.map((item) => (
          <SwiperSlide key={item.id} className={s["root__slide"]}>
            <StoryItem item={item} />
          </SwiperSlide>
        ))}

        <Button.Default
          className={clsx(
            s["root__navigation"],
            s["root__navigation_prev"],
            isBeginning && s["root__navigation_disabled"],
          )}
          onClick={handlePrev}
        >
          <Icon
            direction={IconDirection.DOWN}
            size={IconSize.M}
            type={IconType.ARROW_RIGHT}
          />
        </Button.Default>

        <Button.Default
          className={clsx(
            s["root__navigation"],
            s["root__navigation_next"],
            isEnd && s["root__navigation_disabled"],
          )}
          onClick={handleNext}
        >
          <Icon
            direction={IconDirection.UP}
            size={IconSize.M}
            type={IconType.ARROW_RIGHT}
          />
        </Button.Default>

        {/** Компенсируется отступ для пагинации */}
        <div className={s["root__pagination-placeholder"]} />
      </Swiper>
    </div>
  );
};

export default observer(WidgetStories);
