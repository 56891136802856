import { useEffect, useMemo, useState } from "react";

import useWidth from "@app/hooks/useWidth";

type PieConfigType = {
  cx: number;
  cy: number;
  outerRadius: number;
  innerRadius: number;
  cornerRadius: number;
};

type usePieChartConfigType = {
  pieChartSize: {
    width: number;
    height: number;
  };
  mainPieConfig: PieConfigType;
  extraPieConfig: PieConfigType;
};

export const usePieChartConfig = (
  chartRef: React.RefObject<HTMLDivElement>,
): usePieChartConfigType => {
  const { isPhone } = useWidth();

  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    const container = chartRef.current;

    if (!container) {
      return;
    }

    const handleResize = () => {
      setWidth(container.offsetWidth);
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [chartRef]);

  const pieChartSize = useMemo(
    () => ({
      width,
      height: width / 2,
    }),
    [width],
  );

  const mainPieConfig = useMemo(() => {
    const mainPieWidth = isPhone ? 14 : 18;

    const outerRadius = pieChartSize.height - 2;
    const innerRadius = outerRadius - mainPieWidth;

    return {
      cx: pieChartSize.width / 2,
      cy: pieChartSize.height,
      outerRadius,
      innerRadius,
      cornerRadius: mainPieWidth,
    };
  }, [isPhone, pieChartSize]);

  const extraPieConfig = useMemo(() => {
    const extraPieWidth = isPhone ? 6 : 8;

    const outerRadius = mainPieConfig.innerRadius - extraPieWidth;
    const innerRadius = outerRadius - extraPieWidth;

    return {
      cx: mainPieConfig.cx,
      cy: mainPieConfig.cy,
      outerRadius,
      innerRadius,
      cornerRadius: extraPieWidth,
    };
  }, [isPhone, mainPieConfig]);

  return { pieChartSize, mainPieConfig, extraPieConfig };
};
