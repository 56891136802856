import clsx from "clsx";
import { memo, useCallback } from "react";

import type { IStoryModel } from "@repo/data/store/models/StoryModel";
import Button from "@repo/ui/Button";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useModalStories from "@app/hooks/useModalStories";

import * as s from "./StoryItem.module.scss";

export type StoryItemProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
  /** Данные истории. */
  item: IStoryModel;
};

const StoryItem: React.FC<StoryItemProps> = ({
  className,
  item,
}: StoryItemProps) => {
  const { handleOpen } = useModalStories();

  const handleClick = useCallback(() => {
    handleOpen(item);
  }, [item, handleOpen]);

  return (
    <Button.Default
      className={clsx(s["root"], item.isViewed && s["root_viewed"], className)}
      onClick={handleClick}
    >
      <div
        className={clsx(
          s["root__image"],
          item.isPinned && s["root__image_pinned"],
        )}
        style={{
          backgroundImage: `url(${item.attachment})`,
        }}
      />

      <Typography
        className={s["root__title"]}
        typographyType={TypographyType.CAPTION}
        typographyWeight={TypographyWeight._500}
      >
        {item.title}
      </Typography>
    </Button.Default>
  );
};

export default memo(StoryItem);
