// extracted by mini-css-extract-plugin
var _1 = "root__RtnfU";
var _2 = "root__button__gRyxY";
var _3 = "root__content__gD8BT";
var _4 = "root__detail__ygLgi";
var _5 = "root__detail-text__XTklh";
var _6 = "root__detail-title__yyuf5";
var _7 = "root__details__IkyOl";
var _8 = "root__header__FrQiR";
var _9 = "root__name__JBsyS";
var _a = "root__position__jrVLo";
var _b = "root__tags__SQz3p";
var _c = "root__title__x0IcO";
var _d = "root_with-border__Y7Yuv";
export { _1 as "root", _2 as "root__button", _3 as "root__content", _4 as "root__detail", _5 as "root__detail-text", _6 as "root__detail-title", _7 as "root__details", _8 as "root__header", _9 as "root__name", _a as "root__position", _b as "root__tags", _c as "root__title", _d as "root_with-border" }
