export enum SwitchColor {
  /** Переключатель основной. */
  PRIMARY = "primary",
  /** Переключатель тем. */
  THEME = "theme",
}

export enum SwitchSize {
  /** height: 32px; */
  M = "m",
  /** height: 36px; */
  L = "l",
}
