import { createFileRoute } from "@tanstack/react-router";

import Loader, { LoaderSize } from "@repo/ui/Loader";

import LayoutPage from "@app/pages/auth/LayoutPage";
import CollapseTabs from "@app/pages/employee/CollapseTabs";
import PersonalData from "@app/pages/employee/PersonalData";

import * as s from "./employee.module.scss";

const AuthEmployeePage: React.FC = () => {
  return (
    <LayoutPage className={s["employee"]}>
      <PersonalData />
      <CollapseTabs />
    </LayoutPage>
  );
};

export const Route = createFileRoute("/_auth/employee/$employeeId")({
  component: AuthEmployeePage,
  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});
