import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import type { CollectionType } from "@repo/types/collection";
import { getAdjacentValues } from "@repo/utils/array";
import { linearizeCollection } from "@repo/utils/collection";

import PaginationStore from "../../local/PaginationStore";
import type { IStoryModel } from "../../models/StoryModel";

import { LIMIT_STORIES } from "./config";
import { normalizeStories } from "./normalize";

type PrivateFields = "_stories" | "_activeStory";

class StoriesStore {
  private readonly _meta = new MetaStore();
  private readonly _pagination = new PaginationStore(LIMIT_STORIES);

  private _stories: CollectionType<number, IStoryModel> = {
    order: [],
    entities: {},
  };
  private _total: number = 0;

  private _activeStory: IStoryModel | null = null;

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _stories: observable,
      _activeStory: observable.ref,

      stories: computed,

      setActiveStory: action,
    });
  }

  get meta(): Meta {
    return this._meta.meta;
  }

  get order(): number[] {
    return this._stories.order;
  }

  get stories(): IStoryModel[] {
    return linearizeCollection(this._stories);
  }

  get activeStory(): IStoryModel | null {
    return this._activeStory;
  }

  getStoryById(id: IStoryModel["id"]): IStoryModel | null {
    return this._stories.entities[id] ?? null;
  }

  setActiveStory(story: IStoryModel | null): void {
    this._activeStory = story;
  }

  async fetchStories(): Promise<void> {
    this._meta.setLoadingStart();

    const { data, isError } = await apiWithChecks(ApiPath.STORY_LIST, "GET", {
      data: {
        limit: this._pagination.pageInfo.count,
        page: this._pagination.pageInfo.currentPage,
      },
    });

    if (!data || isError) {
      this._meta.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      const normalizedStories = normalizeStories(data.items);

      this._stories = {
        order: [...this._stories.order, ...normalizedStories.order],
        entities: {
          ...this._stories.entities,
          ...normalizedStories.entities,
        },
      };
      this._total = data.total;

      this._meta.setLoadedSuccessMeta();
    });
  }

  async fetchNextStories(): Promise<void> {
    if (this.order.length >= this._total) {
      return;
    }

    this._pagination.nextPage();

    await this.fetchStories();
  }

  async fetchSiblingSlides(): Promise<void> {
    const siblingStoryIds = getAdjacentValues(this.activeStory?.id, this.order);

    const promiseSlides = siblingStoryIds.map(
      async (id: number) => await this.getStoryById(id)?.fetchSlides(),
    );

    await Promise.all(promiseSlides);
  }
}

export default StoriesStore;
