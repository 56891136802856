import type { s3 } from "@repo/api/types";

import type { IS3Resource } from "./types";

class S3ResourceModel implements IS3Resource {
  readonly url: string;

  constructor(apiData: s3.S3ResourceApi) {
    this.url = `storage/${apiData.route}`;
  }
}

export default S3ResourceModel;
