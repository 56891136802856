import clsx from "clsx";
import { memo, useCallback, useState } from "react";
import { Virtuoso } from "react-virtuoso";

import { Card, CardEmployee, type EmployeeInfo } from "@repo/ui/Card";

import useWidth from "@app/hooks/useWidth";
import { useContentContext } from "@app/pages/auth/Layout";

import { EMPLOYEE_DATA } from "../mocks/__mocks__";

import * as s from "./EmployeeList.module.scss";

export type EmployeeListProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const EmployeeList: React.FC<EmployeeListProps> = ({
  className,
}: EmployeeListProps) => {
  const containerRef = useContentContext();

  const { isPhone } = useWidth();

  const [employees, setEmployees] = useState<EmployeeInfo[]>(() =>
    EMPLOYEE_DATA.slice(0, 3),
  );

  const loadMore = useCallback(() => {
    return setTimeout(() => {
      setEmployees((prevState) => [
        ...prevState,
        ...EMPLOYEE_DATA.slice(prevState.length, prevState.length + 10),
      ]);
    }, 500);
  }, []);

  const itemContent = useCallback(
    (index: number, employee: EmployeeInfo) => (
      <CardEmployee
        key={employee.id}
        className={clsx(
          s["root__card"],
          EMPLOYEE_DATA.length - 1 === index && s["root__card_last"],
        )}
        employee={employee}
        isMobile={isPhone}
        withBorder={false}
      />
    ),
    [isPhone],
  );

  return (
    <Card className={clsx(s["root"], className)}>
      <Virtuoso
        useWindowScroll
        customScrollParent={containerRef.current ?? undefined}
        data={employees}
        endReached={loadMore}
        increaseViewportBy={200}
        itemContent={itemContent}
        style={{ flex: "1", height: "100%" }}
        totalCount={EMPLOYEE_DATA.length}
      />
    </Card>
  );
};

export default memo(EmployeeList);
