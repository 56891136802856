import { LinkProps } from "@tanstack/react-router";
import clsx from "clsx";
import React, { memo } from "react";

import { Card } from "@repo/ui/Card";
import Link, { LinkColor } from "@repo/ui/Link";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import WidgetTransition from "../WidgetTransition";

import WidgetCardNumber, { WidgetCardNumberType } from "./WidgetCardNumber";
import { WidgetCardColor } from "./config";

import * as s from "./WidgetCard.module.scss";

type Props = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Цвет карточки */
  color: WidgetCardColor;
  /** Заголовок */
  title: string;
  /** Большая иконка */
  iconLarge: string;
  /** Маленькая иконка */
  iconSmall: string;
  /** Текст ссылки */
  linkText?: string;
  /** Ссылка */
  linkTo: LinkProps["to"];
  /** Числа для карточки */
  cardNumbers: WidgetCardNumberType[];
  /** Применяет mix-blend-mode: luminosity */
  isLuminosity?: boolean;
};

const WidgetCard: React.FC<Props> = ({
  className,
  title,
  cardNumbers,
  linkText = "Посмотреть все",
  linkTo,
  iconLarge,
  iconSmall,
  color,
  isLuminosity = false,
}) => {
  const { isDesktop } = useWidth();

  return (
    <WidgetTransition className={className}>
      <Card className={clsx(s["root"], s[`root_color-${color}`])}>
        <div className={s["root__left"]}>
          <Typography
            className={s["root__title"]}
            typographyType={isDesktop ? TypographyType.H3 : TypographyType.H2}
            typographyWeight={TypographyWeight._700}
          >
            {title}
          </Typography>
          <div
            className={clsx(
              s["root__icon"],
              s["root__icon_large"],
              isLuminosity && s["root__icon_luminosity"],
            )}
            style={{
              backgroundImage: `url(${iconLarge})`,
            }}
          />
          <div
            className={clsx(
              s["root__icon"],
              s["root__icon_small"],
              isLuminosity && s["root__icon_luminosity"],
            )}
            style={{
              backgroundImage: `url(${iconSmall})`,
            }}
          />
        </div>
        <div className={s["root__right"]}>
          <div className={s["root__numbers"]}>
            {cardNumbers.map((cardNumber) => (
              <WidgetCardNumber
                key={cardNumber.title}
                cardNumber={cardNumber}
              />
            ))}
          </div>
          <Link
            color={LinkColor.SECONDARY}
            isMobile={!isDesktop}
            text={linkText}
            to={linkTo}
          />
        </div>
      </Card>
    </WidgetTransition>
  );
};

export default memo(WidgetCard);
