import { action, makeObservable, observable, runInAction } from "mobx";
import { type Area } from "react-easy-crop";

import { apiWithChecks } from "@repo/api/apiWithChecks";
import { ApiPath } from "@repo/api/fetchApi";
import { ILocalStore } from "@repo/data/store/interfaces/ILocalStore";
import MetaStore, { type Meta } from "@repo/data/store/models/MetaStore";
import S3ResourceModel from "@repo/data/store/models/S3ResourceModel";
import GlobalUserStore from "@repo/data/store/single/GlobalUserStore";
import { getCroppedImg, readFile } from "@repo/ui/ImageCropper";

type PrivateFields = "_image" | "_croppedImage";

class AvatarChangeStore implements ILocalStore {
  private readonly _metaStore = new MetaStore();

  private _image: string | null = null;
  private _croppedImage: Blob | null = null;

  get meta(): Meta {
    return this._metaStore.meta;
  }

  get image(): string | null {
    return this._image;
  }

  constructor() {
    makeObservable<this, PrivateFields>(this, {
      _image: observable,
      _croppedImage: observable,

      setImage: action,
      setCroppedImage: action.bound,
    });
  }

  async setImage(file: File): Promise<void> {
    this._image = await readFile(file);
  }

  async setCroppedImage(cropParams: Area): Promise<void> {
    if (!this._image) {
      return;
    }

    this._croppedImage = await getCroppedImg(this._image, cropParams);
  }

  async changeImage(): Promise<void> {
    if (!this._croppedImage) {
      return;
    }

    this._metaStore.setLoadingStart();

    const { data, isError } = await apiWithChecks(
      ApiPath.USER_CURRENT_AVATAR,
      "POST",
      {
        data: { file: this._croppedImage },
        multipartFormData: true,
      },
    );

    if (!data || isError) {
      this._metaStore.setLoadedErrorMeta();
      return;
    }

    runInAction(() => {
      const avatar = new S3ResourceModel(data).url;
      GlobalUserStore.getInstance().setAvatar(avatar);

      this._metaStore.setLoadedSuccessMeta();
    });
  }

  destroy(): void {
    this._metaStore.destroy();
  }
}

export default AvatarChangeStore;
