// extracted by mini-css-extract-plugin
var _1 = "tag__kgp1R";
var _2 = "tag_angle-bottom-left__jf3Jw";
var _3 = "tag_angle-bottom-right__DeF6w";
var _4 = "tag_angle-top-left__kK9LW";
var _5 = "tag_angle-top-right__dt6Sg";
var _6 = "tag_color-default__K2XUW";
var _7 = "tag_color-primary__lvsSI";
var _8 = "tag_color-secondary__w5CbS";
var _9 = "tag_color-tertiary__NIa4F";
var _a = "tag_no-pointer__qRFAS";
var _b = "tag_size-m__Q4C2p";
var _c = "tag_size-s__y9ddo";
var _d = "tag_size-xs__o5QBC";
export { _1 as "tag", _2 as "tag_angle-bottom-left", _3 as "tag_angle-bottom-right", _4 as "tag_angle-top-left", _5 as "tag_angle-top-right", _6 as "tag_color-default", _7 as "tag_color-primary", _8 as "tag_color-secondary", _9 as "tag_color-tertiary", _a as "tag_no-pointer", _b as "tag_size-m", _c as "tag_size-s", _d as "tag_size-xs" }
