import { motion, AnimatePresence } from "framer-motion";
import { memo } from "react";

import { IconType } from "@repo/types/icon";
import Icon, { IconSize } from "@repo/ui/Icon";

import Switch, { SwitchColor, SwitchSize, type SwitchProps } from "../Switch";

import { MOON_VARIANTS, SUN_VARIANTS } from "./config";

import * as s from "./SwitchTheme.module.scss";

export type SwitchThemeProps = Omit<SwitchProps, "size" | "color" | "label">;

const SwitchTheme: React.FC<SwitchThemeProps> = ({
  ...props
}: SwitchThemeProps) => {
  return (
    <Switch
      color={SwitchColor.THEME}
      label="Сменить тему"
      size={SwitchSize.L}
      {...props}
    >
      {({ checked }) => (
        <AnimatePresence initial={false} mode="wait">
          <motion.span
            key={checked ? IconType.SUN : IconType.MOON}
            animate="default"
            className={s["switch__icon"]}
            exit="exit"
            initial="initial"
            transition={{ duration: 0.2 }}
            variants={checked ? SUN_VARIANTS : MOON_VARIANTS}
          >
            <Icon
              size={IconSize.S}
              type={checked ? IconType.SUN : IconType.MOON}
            />
          </motion.span>
        </AnimatePresence>
      )}
    </Switch>
  );
};

export default memo(SwitchTheme);
