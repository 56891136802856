/**
 * Список ключей табов.
 *
 * Корневые ключи должны иметь маску `@{key}/`.
 * @example `MENU = "@menu/"`
 */
export enum TabKey {
  /** Главное меню */
  MENU = "@menu/",
  /** Главное меню -> Основные сервисы */
  MENU_MAIN = "@menu/main/",
  /** Главное меню -> Основные сервисы -> Задачи */
  MENU_MAIN_TASKS = "@menu/main/tasks",
  /** Главное меню -> Основные сервисы -> Заявки */
  MENU_MAIN_POSTULATIONS = "@menu/main/postulations",
}
