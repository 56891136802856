import ButtonDefault from "./Button";
import ButtonBase from "./ButtonBase";
import ButtonText from "./ButtonText";

const Button = {
  Default: ButtonDefault,
  Base: ButtonBase,
  Text: ButtonText,
};

export default Button;

export type { ButtonDefaultProps } from "./Button";
export type { ButtonBaseProps } from "./ButtonBase";
export type { ButtonTextProps } from "./ButtonText";

export { ButtonSize, ButtonColor, ButtonTheme } from "./config";
