import type { story } from "@repo/api/types";
import type { SlideType, StoryType } from "@repo/types/story";

import S3ResourceModel from "../S3ResourceModel";

export const normalizeSlides = (rawData: story.SlideApi[]): SlideType[] =>
  rawData.map((slide) => ({
    storyId: slide.story_id,
    id: slide.id,
    title: slide.title,
    text: slide.text,
    action: slide.action,
    createdAt: slide.created_at,
    attachment: new S3ResourceModel(slide.attachment).url,
  }));

export const normalizeStory = (rawData: story.StoryApi): StoryType => ({
  id: rawData.id,
  title: rawData.title,
  isPinned: rawData.is_pinned,
  isViewed: rawData.is_viewed,
  createdAt: rawData.created_at,
  attachment: new S3ResourceModel(rawData.attachment).url,
});
