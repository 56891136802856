import clsx from "clsx";
import { memo, useCallback, useRef, useState } from "react";

import { IconType } from "@repo/types/icon";
import Button, { ButtonColor } from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";

import * as s from "./InputSearch.module.scss";

export type InputSearchProps = {
  /** Дополнительные CSS классы для компонента. */
  className?: string;
  /** Значение поля ввода. */
  value: string;
  /** Обработчик изменения значения поля ввода. */
  onChange: (value: string) => void;
  /** Callback кнопки поиска. */
  onSearch: () => void;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange">;

const InputSearch: React.FC<InputSearchProps> = ({
  className,
  placeholder = "Что вас интересует ?",
  value,
  onChange,
  onSearch,
  ...props
}: InputSearchProps) => {
  const ref = useRef<HTMLInputElement | null>(null);

  const [isFocused, setIsFocused] = useState<boolean>(false);

  const handleBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleClick = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(() => {
    const input = ref.current;

    if (!input || isFocused) {
      return;
    }

    input.focus();

    setIsFocused(true);
  }, [isFocused]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (!onChange) {
        return;
      }

      onChange(e.target.value);
    },
    [onChange],
  );

  const handleSearch = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();

      onSearch();
    },
    [onSearch],
  );

  return (
    <div
      className={clsx(s["root"], className)}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      <Icon
        className={s["root__icon"]}
        size={IconSize.M}
        type={IconType.SEARCH}
      />

      <input
        ref={ref}
        className={clsx(s["root__field"])}
        placeholder={placeholder}
        type="search"
        value={value}
        onChange={handleChange}
        {...props}
      />

      <Button.Base
        className={s["root__button"]}
        color={ButtonColor.SECONDARY}
        onClick={handleSearch}
      >
        <Icon size={IconSize.M} type={IconType.ARROW_RIGHT} />
      </Button.Base>
    </div>
  );
};

export default memo(InputSearch);
