import clsx from "clsx";
import { observer } from "mobx-react-lite";

import GlobalUserStore from "@repo/data/store/single/GlobalUserStore";
import { AvatarImage, AvatarSize } from "@repo/ui/Avatar";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import useWidth from "@app/hooks/useWidth";

import DetailList from "../../shared/DetailList";
import EmployeeStatus from "../../shared/EmployeeStatus";

import * as s from "./PersonalData.module.scss";

export type PersonalDataProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const PersonalData: React.FC<PersonalDataProps> = ({
  className,
}: PersonalDataProps) => {
  const { isPhone } = useWidth();

  const userStore = GlobalUserStore.getInstance();

  return (
    <div className={clsx(s["personal"], className)}>
      <div className={s["personal__avatar"]}>
        <AvatarImage
          alt={userStore.data.fullName}
          className={s["personal__avatar-img"]}
          round={!isPhone}
          size={AvatarSize.UNSET}
          src={userStore.data.avatar}
        />
      </div>

      <div className={s["personal__content"]}>
        <EmployeeStatus />

        <Typography
          Component="h1"
          typographyType={TypographyType.H1}
          typographyWeight={TypographyWeight._700}
        >
          Вяткина Наталья Васильевна
        </Typography>

        <DetailList />
      </div>
    </div>
  );
};

export default observer(PersonalData);
