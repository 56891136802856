import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";

import { useLocalStore } from "@repo/data/hooks";
import { ModalsBaseKeys } from "@repo/data/store/single/GlobalModalsStore";
import GlobalUserStore from "@repo/data/store/single/GlobalUserStore";
import { IconType } from "@repo/types/icon";
import Avatar from "@repo/ui/Avatar";
import Button, { ButtonColor } from "@repo/ui/Button";
import Icon, { IconSize } from "@repo/ui/Icon";
import Typography, {
  TypographyType,
  TypographyWeight,
} from "@repo/ui/Typography";

import { useModalsStoreOpenState } from "@app/hooks/useModalsStoreOpenState";
import useWidth from "@app/hooks/useWidth";
import { AvatarChangeStore } from "@app/store/local/AvatarChangeStore";

import DetailList from "../../shared/DetailList";
import EmployeeStatus from "../../shared/EmployeeStatus";
import ModalAvatar from "../ModalAvatar";
import ProfileControls from "../ProfileControls";

import * as s from "./PersonalData.module.scss";

export type PersonalDataProps = {
  /** Дополнительный CSS-класс для компонента. */
  className?: string;
};

const PersonalData: React.FC<PersonalDataProps> = ({
  className,
}: PersonalDataProps) => {
  const { isPhone } = useWidth();

  const userStore = GlobalUserStore.getInstance();
  const avatarStore = useLocalStore(() => new AvatarChangeStore());

  const modalOpenState = useModalsStoreOpenState(ModalsBaseKeys.AVATAR_CHANGE);

  const handleAvatarChange = useCallback(
    async (files: FileList) => {
      const [photo] = files;

      if (!photo) {
        return;
      }

      await avatarStore.setImage(photo);

      modalOpenState.open();
    },
    [avatarStore, modalOpenState],
  );

  return (
    <div className={clsx(s["personal"], className)}>
      <Avatar
        alt={userStore.data.fullName}
        className={s["personal__avatar"]}
        isMobile={isPhone}
        src={userStore.data.avatar}
        onChange={handleAvatarChange}
      />

      <ModalAvatar store={avatarStore} />

      <div className={s["personal__content"]}>
        <EmployeeStatus className={s["personal__status"]} />

        <Typography
          Component="h1"
          className={s["personal__title"]}
          typographyType={TypographyType.H1}
          typographyWeight={TypographyWeight._700}
        >
          Вяткина Наталья Васильевна
        </Typography>

        <DetailList className={s["personal__details"]} />

        <div className={s["personal__controls"]}>
          <ProfileControls />

          <Button.Text
            className={s["personal__edit"]}
            color={ButtonColor.DEFAULT}
          >
            {isPhone ? (
              <Icon size={IconSize.XS} type={IconType.EDIT} />
            ) : (
              <Typography
                Component="span"
                typographyType={TypographyType.BTN_SM}
                typographyWeight={TypographyWeight._700}
              >
                Редактировать
              </Typography>
            )}
          </Button.Text>
        </div>
      </div>
    </div>
  );
};

export default observer(PersonalData);
