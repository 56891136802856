import { createContext, type MutableRefObject, useContext } from "react";

type ContentContextType = MutableRefObject<HTMLDivElement | null> | null;

export const ContentContext = createContext<ContentContextType>(null);

export function useContentContext() {
  const context = useContext(ContentContext);

  if (!context) {
    throw new Error("useContentContext must be used within an ContentContext");
  }

  return context;
}
