import { createFileRoute } from "@tanstack/react-router";

import GlobalStoriesStore from "@repo/data/store/single/GlobalStoriesStore";
import Loader, { LoaderSize } from "@repo/ui/Loader";

import WidgetsDashboard from "@app/pages/auth/widgets/WidgetsDashboard";

const AuthIndexPage: React.FC = () => {
  return <WidgetsDashboard />;
};

export const Route = createFileRoute("/_auth/")({
  component: AuthIndexPage,

  loader: async () => {
    const storiesStore = GlobalStoriesStore.getInstance();

    await storiesStore.fetchStories();
  },

  shouldReload: false,

  pendingComponent: () => <Loader isAbsolute size={LoaderSize.L} />,
});
