import type { rule } from "@repo/api/types";

import { InterfaceRuleType } from "./types";

export const normalizeRule = (
  rawData: rule.InterfaceRuleApi,
): InterfaceRuleType => ({
  id: rawData.id,
  key: rawData.key,
  title: rawData.title,
  description: rawData.description,
  action: rawData.action,
  value: rawData.value ?? {},
});
