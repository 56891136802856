// extracted by mini-css-extract-plugin
var _1 = "switch__yNMIi";
var _2 = "switch__input__FFanO";
var _3 = "switch__label__RZyst";
var _4 = "switch__label_color-primary__qUNQL";
var _5 = "switch__label_color-theme__khU9Z";
var _6 = "switch__label_size-l__lB0go";
var _7 = "switch__label_size-m__fW5iC";
var _8 = "switch__pin__LZFWN";
var _9 = "switch__pin_color-primary__bbTgb";
var _a = "switch__pin_color-theme__h2wE7";
var _b = "switch__pin_size-l__H3mGc";
var _c = "switch__pin_size-m__esUsl";
var _d = "switch__text__YxdLo";
export { _1 as "switch", _2 as "switch__input", _3 as "switch__label", _4 as "switch__label_color-primary", _5 as "switch__label_color-theme", _6 as "switch__label_size-l", _7 as "switch__label_size-m", _8 as "switch__pin", _9 as "switch__pin_color-primary", _a as "switch__pin_color-theme", _b as "switch__pin_size-l", _c as "switch__pin_size-m", _d as "switch__text" }
