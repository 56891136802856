import { memo } from "react";

import ChartLegendItem from "./ChartLegendItem";
import { ChartLegendItemEnum } from "./ChartLegendItem";

import * as s from "./ChartLegend.module.scss";

export type ChartLegendProps = {
  /** Если true, значение будет размыто. */
  isVisible: boolean;
};

const ChartLegend: React.FC<ChartLegendProps> = ({
  isVisible,
}: ChartLegendProps) => {
  return (
    <div className={s["root"]}>
      <ChartLegendItem
        id={ChartLegendItemEnum.INCOME}
        isVisible={isVisible}
        value={32032}
      />

      <ChartLegendItem
        id={ChartLegendItemEnum.EXPENSE}
        isVisible={isVisible}
        value={-5735.77}
      />
    </div>
  );
};

export default memo(ChartLegend);
