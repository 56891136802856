// extracted by mini-css-extract-plugin
var _1 = "root__KDJjW";
var _2 = "root__button__E4z5O";
var _3 = "root__content__A8k1n";
var _4 = "root__icon__EAzhF";
var _5 = "root__subtitle__X0v8G";
var _6 = "root__title__cPxw_";
var _7 = "root__wrapper__tsgCi";
var _8 = "root__wrapper_collapsable__Xuzyq";
export { _1 as "root", _2 as "root__button", _3 as "root__content", _4 as "root__icon", _5 as "root__subtitle", _6 as "root__title", _7 as "root__wrapper", _8 as "root__wrapper_collapsable" }
